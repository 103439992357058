import * as React from "react";
import { FC } from "react";
import { AwardMonteCarloEndpoint } from "../../../entrypoints/award-monte-carlo";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout
    lang={LANG.deCH}
    entrypoint={"award-monte-carlo"}
    page={"auszeichnung_monte-carlo"}
  >
    <AwardMonteCarloEndpoint lang={LANG.deCH} />
  </DefaultLayout>
);
